import {
	NLPSearchCustomDefinitionType,
	NLPSearchResultFormat,
	type NLPSearchType,
} from '../../../ai-answer-dialog/types';
import { type CuratedDefinitionType } from '../gql/curated-definition-query';
import { type PrecomputedDefinitionType } from '../gql/precomputed-definition-query';

export const mapCustomDefinitionToNLPSearchType = (
	definition: CuratedDefinitionType | PrecomputedDefinitionType,
): NLPSearchType => {
	const definitionReference = definition.confluenceEntity;

	return {
		nlpResult: definition.definition,
		uniqueSources: definitionReference
			? [
					{
						id: definitionReference?.id || '',
						title: definitionReference?.title || '',
						url:
							'referenceUrl' in definition
								? definition.referenceUrl || ''
								: 'links' in definitionReference &&
									  definitionReference.links.base &&
									  definitionReference.links.webUi
									? `${definitionReference.links.base}${definitionReference.links.webUi}`
									: '',
						type: definitionReference?.type || '',
						lastModified: '',
						iconUrl: '',
						spaceName: definitionReference?.space?.name || '',
						spaceUrl: null,
					},
				]
			: [],
		nlpResultEditor:
			'editor' in definition
				? {
						name: definition.editor?.name || '',
						id: definition.editor?.accountId || '',
					}
				: undefined,
		disclaimer: null,
		errorState: null,
		format: NLPSearchResultFormat.MARKDOWN,
		customDefinitionType:
			'editor' in definition
				? NLPSearchCustomDefinitionType.CURATED
				: NLPSearchCustomDefinitionType.PRECOMPUTED,
	};
};
