export interface PrecomputedDefinitionType {
	definition?: string;
	createdAt?: string;
	confluenceEntity?: {
		id: string;
		type: string | null;
		title: string | null;
		space: {
			name: string | null;
		} | null;
		links: {
			base: string | null;
			webUi: string | null;
		};
	} | null;
	message?: string | null;
	__typename: string;
}

export interface PrecomputedDefinitionQueryType {
	knowledgeDiscovery: {
		autoDefinition: PrecomputedDefinitionType;
	};
}

export interface PrecomputedDefinitionQueryVariables {
	workspaceId: string;
	keyPhrase: string;
	contentId: string;
}

export const PrecomputedDefinitionQuery = `
query PrecomputedDefinitionQuery(
		$workspaceId: String!
		$keyPhrase: String!
        $contentId: String!
	) {
		knowledgeDiscovery {
			autoDefinition(
				workspaceId: $workspaceId
				keyPhrase: $keyPhrase
				contentId: $contentId
			) @optIn(to: "KnowledgeDiscovery Get auto definition") {
				__typename
				... on KnowledgeDiscoveryAutoDefinition {
					definition
                    createdAt
					confluenceEntity {
						... on ConfluencePage {
							id
							type
							title
							space {
								name
							}
                            links {
                                base
                                webUi
                            }
						}
						... on ConfluenceBlogPost {
							id
							type
							title
							space {
								name
							}
                            links {
                                base
                                webUi
                            }
						}
					}
				}
				... on QueryError {
					message
                    extensions {
                        statusCode
                        errorType
                    }
				}
			}
		}
	}
`;
